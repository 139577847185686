import React from "react";
import ActionLogger from "action-logger";
import PropTypes from "prop-types";
import Cookies from "universal-cookie";
import { authFormConstants } from "rtr-constants/auth";
import { COOKIES } from "rtr-constants";
import FormattingUtils from "helpers/FormattingUtils";
import { signIn } from "helpers/session-helpers";

const { Views } = authFormConstants;

class OauthButton extends React.Component {
  static propTypes = {
    isDAC: PropTypes.bool,
    additionalClassName: PropTypes.string,
    id: PropTypes.string,
    includeIcon: PropTypes.bool,
    isRegistration: PropTypes.bool.isRequired,
    provider: PropTypes.oneOf(["facebook", "google"]).isRequired,
  };

  authUrl(provider) {
    switch (provider) {
      case "facebook":
        return "/account/auth/facebook";
      case "google":
        return "/account/auth/google_oauth2";
    }
  }

  oauthSignIn() {
    this.logOauthClick();
    const cookies = new Cookies();
    const isCasV1Enabled = cookies.get(COOKIES.CAS_V1_ENABLED) === "true";
    if (isCasV1Enabled) {
      signIn({
        mode: "login",
        provider: this.props.provider,
      });
      return;
    }

    const width = 560;
    const height = 645;
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || screen.width;
    const left = screenWidth / 2 - width / 2;

    window.open(
      `${this.authUrl(this.props.provider)}?popup=true&dac=${this.props.isDAC || false}`,
      "",
      `width=${width}, height=${height}, bottom=0, left=${left}`
    );
  }

  logOauthClick() {
    const formType = this.props.isRegistration ? Views.registration : Views.login;

    ActionLogger.logAction({
      objectType: "user",
      action: formType + "_open",
      provider: this.props.provider,
    });
  }

  render() {
    const providerText = FormattingUtils.capitalize(this.props.provider);
    const copy = this.props.isRegistration ? "Continue" : "Sign in";

    const iconClass = this.props.provider === "google" ? "reb-icon_google" : "reb-fb_logo";

    return (
      <div className={`oauth-button ${this.props.additionalClassName}`}>
        <button
          type="button"
          className={`btn-secondary btn-${this.props.provider}`}
          id={this.props.id}
          onClick={() => {
            this.oauthSignIn();
          }}>
          {this.props.isRegistration && this.props.includeIcon && <div className={iconClass} />}
          {`${copy} with ${providerText}`}
        </button>
      </div>
    );
  }

  static defaultProps = {
    additionalClassName: "",
    isRegistration: true,
  };
}

export default OauthButton;
